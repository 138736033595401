.mobile-nav__container {
    transition: transform ease-in-out 300ms;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 20%;
    min-width: 300px;

    transform: translate(-100%, 0);
}

.mobile-nav__container.visible {
    transform: translate(0, 0);
}

.mobile-nav__container > * {
    margin-bottom: 1em;
}

.mobile-nav__container > a {
    text-decoration: none;
}